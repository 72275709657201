import { React, useEffect, useState } from "react";
import "./FinishPage.scss";
import ScanSuccess from "../Images/ScanSuccess.gif";
import ScanSuccessLastFrame from "../Images/LastFrame.gif";
import NoviraLogo from "../Images/NoviraLogo.webp";

function FinishPage() {
  const [animation, setAnimation] = useState(ScanSuccess);

  useEffect(() => {
    setTimeout(() => {
      setAnimation(ScanSuccessLastFrame);
    }, 800);
  }, []);
  return (
    <div className="finish__base">
      <div className="finish__title">
        <h1 className="finish__title__text">Returning to homescreen!</h1>
      </div>
      <div className="finish__image">
        <img className="finish__image__logo" src={animation} alt="Success" />
      </div>
      <div className="finish__footer">
        <img className="finish__footer__logo" src={NoviraLogo} alt="VenueNow" />
      </div>
    </div>
  );
}

export default FinishPage;
