import { React } from "react";
import "./Homescreen.scss";
// import Ad1 from "../Images/advert1.jpg";
// import Ad2 from "../Images/advert2.jpg";
import NoviraLogo from "../Images/NoviraLogo.webp";
import HomeVideo from "../Images/Novira-Eco-Bin_food-kiosk.mp4";

// const imageArray = [Logo, Ad2, Ad1];

function Homescreen({ homeScreenClicked }) {
  // ------ Ads for HomeScreen ------- //

  // const [count, setCount] = useState(0);

  // useEffect(() => {
  //   const timerId = setInterval(() => {
  //     setCount((count) => count + 1);
  //   }, 4000);

  //   return () => clearInterval(timerId);
  // }, []);

  // const image = imageArray[count % imageArray.length];

  return (
    <div className="home__base" onClick={homeScreenClicked}>
      <div className="home__title">
        <h1 className="home__title__text">Welcome, tap anywhere to begin.</h1>
      </div>
      <div className="home__image">
        <video className="home__image__video" autoPlay loop muted>
          <source src={HomeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      {/* <div className="home__footer">
        <img className="home__footer__logo" src={NoviraLogo} alt="Logo" />
      </div> */}
    </div>
  );
}

export default Homescreen;
