import { React, useState, useEffect } from "react";

import axios from "axios";

import Homescreen from "./Components/Homescreen";
import ScanIdScreen from "./Components/ScanIdScreen";
import CardScanned from "./Components/CardScanned";

import InvalidId from "./Components/InvalidId";
import PackageNotFound from "./Components/PackageNotFound";
import CardUserNotScanned from "./Components/CardUserNotScanned";
import PackageAlreadyScanned from "./Components/PackageAlreadyScanned";
import GenericErrorScreen from "./Components/GenericErrorScreen";
import ServerErrorScreen from "./Components/ServerErrorScreen";
import AuthErrorScreen from "./Components/AuthErrorScreen";

import ScanContainerScreen from "./Components/ScanContainerScreen";
import ContainerScanned from "./Components/ContainerScanned";
import FinishPage from "./Components/FinishPage";

import Sfx from "./Sounds/Sfx.mp3";
import FinishBell from "./Sounds/FinishBell.mp3";
import Error from "./Sounds/error.mp3";
import useSound from "use-sound";

function Kiosk() {
  const [idData, setIdData] = useState("");
  const [packageData, setPackageData] = useState([]);
  const [activePage, setActivePage] = useState("HomePage");

  const [play] = useSound(Sfx);
  const [playFinish] = useSound(FinishBell);
  const [playError] = useSound(Error);

  const token = localStorage.getItem("token");

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    if (activePage === "Finish") {
      finish();
    }
    if (activePage === "HomePage") {
      setPackageData([]);
      setIdData("");
    }
  }, [activePage]);

  const packageArray = (e) => {
    setPackageData((packageData) => [...packageData, e]);
  };
  const idFunction = (scanData) => {
    let isnum = /^\d+$/.test(scanData);
    if (isnum && scanData.length === 12) {
      setIdData(scanData);
      setActivePage("CardScanned");
    } else {
      setActivePage("InvalidScan");
      playError();
    }
  };

  const backHome = () => {
    play();
    setActivePage("HomePage");
  };
  const activateIdPage = () => {
    play();
    setActivePage("IdPage");
  };

  const activatePackagePage = () => {
    play();
    setActivePage("ScanPackagePage");
  };

  const activateScannedPage = () => {
    setActivePage("PackageScanned");
    setTimeout(() => {
      setActivePage("ScanPackagePage");
    }, 800);
  };

  const backToScanPackage = () => {
    setActivePage("ScanPackagePage");
  };

  const setFinishPage = () => {
    handleSubmit();
  };

  const finish = () => {
    setTimeout(() => {
      setActivePage("HomePage");
    }, 2000);
  };

  const handleSubmit = async () => {
    const loginFormData = {
      cardUserId: idData,
      packagingItems: [...new Set(packageData)],
    };

    if (packageData.length === 0) {
      playError();
    }

    if (packageData.length !== 0) {
      try {
        const response = await axios({
          method: "post",
          url: "https://api.venuenow.tech/packaging-transaction/kiosk/checkout",
          data: loginFormData,
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
          },
        });
        if (response.data.statusCode === 201) {
          playFinish();
          setActivePage("Finish");
        }
        if (response.data.statusCode === 500) {
          setActivePage("CardUserNotScanned");
          playError();
        }
      } catch (error) {
        let errorStatusResult = error.response.status.toString();
        const startsWithFive = errorStatusResult.startsWith("5");

        if (errorStatusResult === "404") {
          setActivePage("PackageNotFound");
          playError();
        } else if (errorStatusResult === "409") {
          setActivePage("PackageAlreadyScanned");
          playError();
        } else if (errorStatusResult === "401") {
          playError();
          setActivePage("AuthErrorScreen");
          console.log("Error is Authentication Related");
        } else if (startsWithFive) {
          setActivePage("ServerErrorScreen");
          playError();
        } else {
          setActivePage("GenericErrorScreen");
          playError();
        }
      }
    }
  };

  return (
    <form className="body">
      {activePage === "HomePage" && (
        <Homescreen homeScreenClicked={activateIdPage} />
      )}
      {activePage === "IdPage" && <ScanIdScreen setIdData={idFunction} />}
      {activePage === "InvalidScan" && (
        <InvalidId backToScan={activateIdPage} />
      )}
      {activePage === "CardScanned" && (
        <CardScanned activatePackageScan={activatePackagePage} />
      )}
      {activePage === "ScanPackagePage" && (
        <ScanContainerScreen
          setPackageData={packageArray}
          setActivePage={activateScannedPage}
          finish={setFinishPage}
        />
      )}
      {activePage === "PackageScanned" && (
        <ContainerScanned
          backToScan={backToScanPackage}
          finish={setFinishPage}
        />
      )}
      {activePage === "PackageNotFound" && (
        <PackageNotFound backToScan={backHome} />
      )}
      {activePage === "CardUserNotScanned" && (
        <CardUserNotScanned backToScan={backHome} />
      )}
      {activePage === "PackageAlreadyScanned" && (
        <PackageAlreadyScanned backToScan={backHome} />
      )}{" "}
      {activePage === "AuthErrorScreen" && <AuthErrorScreen />}
      {activePage === "GenericErrorScreen" && (
        <GenericErrorScreen backToScan={backHome} />
      )}
      {activePage === "ServerErrorScreen" && (
        <ServerErrorScreen backToScan={backHome} />
      )}
      {activePage === "Finish" && <FinishPage />}
    </form>
  );
}

export default Kiosk;
