import "./ContainerScanned.scss";
import TickLogo from "../Images/LastFrame.gif";
import NoviraLogo from "../Images/NoviraLogo.webp";

function ContainerScanned({ backToScan, finish }) {
  setTimeout(() => {
    backToScan();
  }, 400);
  return (
    <div className="scanned__base" onClick={finish}>
      <div className="scanned__title">
        <h1 className="scanned__title__text">
          Scan packages using the reader. Tap anywhere to finish.
        </h1>
        <input className="packageScan__image__input" autoFocus type="text" />
      </div>
      <div className="scanned__image__container">
        <img
          className="scanned__image__container__logo"
          src={TickLogo}
          alt="Tick"
        />
      </div>
      <div className="scanned__footer">
        <img className="scanned__footer__logo" src={NoviraLogo} alt="Logo" />
      </div>
    </div>
  );
}

export default ContainerScanned;
