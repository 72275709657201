import React from "react";
import "./CardScanned.scss";

import TickLogo from "../Images/icon-Success.png";
import NoviraLogo from "../Images/NoviraLogo.webp";

function CardScanned({ activatePackageScan }) {
  setTimeout(() => {
    activatePackageScan();
  }, 1000);

  return (
    <div className="cardScanned__base">
      <div className="cardScanned__title">
        <h1 className="cardScanned__title__text">Card successfully scanned!</h1>
      </div>
      <div className="cardScanned__image">
        <img className="cardScanned__image__logo" src={TickLogo} alt="Tick" />
      </div>
      <div className="cardScanned__footer">
        <img
          className="cardScanned__footer__logo"
          src={NoviraLogo}
          alt="Logo"
        />
      </div>
    </div>
  );
}

export default CardScanned;
