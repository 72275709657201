import React from "react";
import "./ScanIdScreen.scss";
import Scan from "../Images/scan.gif";
import NoviraLogo from "../Images/NoviraLogo.webp";

function ScanIdScreen({ setIdData }) {
  const onBlur = (e) => {
    window.setTimeout(() => e.target.focus(), 0);
  };

  return (
    <div className="idScan__base">
      <div className="idScan__title">
        <h1 className="idScan__title__text">
          Swipe your ID card over the scanner below
        </h1>
        <input
          className="idScan__image__input"
          autoFocus
          style={{ border: "none" }}
          type="text"
          onBlur={onBlur}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setIdData(e.target.value);
            }
          }}
        />
      </div>
      <div className="idScan__image">
        <img className="idScan__image__logo" src={Scan} alt="Scan Animation" />
      </div>

      <div className="idScan__footer">
        <img className="idScan__footer__logo" src={NoviraLogo} alt="Logo" />
      </div>
    </div>
  );
}

export default ScanIdScreen;
